import { z } from "zod";

/**
 * Capitalizes the first letter of a string and converts the rest to lowercase.
 *
 * Example:
 * ```typescript
 * upperCaseFirstLetter("Crême Brulée"); // Returns "Crême brulée"
 * ```
 */
export const upperCaseFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

/**
 * Removes diacritical marks (accents) from a string.
 *
 * Example:
 * ```typescript
 * removeAccents("Crême Brülée"); // Returns "Creme Brulee"
 * ```
 *
 * Source: https://github.com/krisk/Fuse/issues/415
 */
export const removeAccents = function (text: string): string {
  return text.normalize("NFD").replace(/[\u0300-\u036F]/g, "");
};

/**
 * Normalizes a string by removing accents and converting it to lowercase.
 *
 * Example:
 * ```typescript
 * normalizeString("Crême Brülée"); // Returns "creme brulee"
 * ```
 *
 * Source: https://stackoverflow.com/a/37511463
 */
export const normalizeString = (text: string): string => removeAccents(text).toLowerCase();

export const isEmail = (text: string): boolean => {
  /**
   * Regular expression to check if a string is in a basic email format.
   *
   * The pattern ensures:
   * - A sequence of characters before the '@' symbol.
   * - An '@' symbol.
   * - A sequence of characters followed by a dot ('.') for the domain.
   * - A sequence of characters for the top-level domain (e.g., '.com').
   *
   * Note: This regex only checks the format, not the validity of the domain.
   */
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return emailRegex.test(text);
};

export const cleanEmail = (email?: string): string | undefined => {
  if (!email) return undefined;

  return email
    .replaceAll(/[,:()"/\s]/g, "") // Some special character invalid for email
    .trim()
    .toLowerCase();
};

export const validateEmail = (email: string) => {
  const emailSchema = z.string().email();
  return emailSchema.safeParse(email).success;
};

/**
 * Permet d'ouvrir le PDF sans la bar de navigation sur le côté
 * cf https://pdfobject.com/pdf/pdf_open_parameters_acro8.pdf#page=7
 */
export const getPdfUrlForIframe = function (url: string) {
  if (url.length) return `${url}#navpanes=0`;
  else return undefined;
};

export const removeEmojis = (text: string): string =>
  text.replace(
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
    ""
  );

// https://www.30secondsofcode.org/js/s/strip-html-tags/#:~:text=All%20you%20really%20need%20is,and%20finally%20the%20closing%20bracket.
export const removeHtmlTags = (text: string) => text.replace(/<[^>]*>/g, "").trim();

export const transformStringIntoArray = (val: string, separator: string) =>
  val.split(separator).map((status) => status.trim());
